import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, InputAdornment } from "@mui/material";

const DefaultAutocomplete = ({
  options = [],
  label,
  name,
  icon,
  value = "",
  onChange = () => {},
  onInputChange = () => {},
  renderButton = false,
  renderButtonFunc = () => {},
  onKeyDown,
}) => {
  const handleAutoComplete = (e, v) => {
    let item = { target: { name: name, value: "", id: null } };
    if (v !== null) {
      item.target.value = v.nome;
      item.target.id = v.id;
      item.target.objeto = v;
    }
    onChange(item);
  };

  const handleChangeInput = ({ target }) => {
    const item = { target: { value: target.value, name: name } };
    onInputChange(item);
  };

  return (
    <Autocomplete
      disablePortal
      clearText="Limpar"
      closeText="Fechar"
      noOptionsText={
        renderButton ? (
          <React.Fragment>
            <span>Nenhum resultado encontrado{"   "}</span>
            <Button variant="contained" size="small" onClick={renderButtonFunc}>
              Adicionar orgão
            </Button>
          </React.Fragment>
        ) : (
          "Nenhum resultado encontrado"
        )
      }
      getOptionLabel={(option) => option.nome || option}
      onChange={handleAutoComplete}
      onInputCapture={handleChangeInput}
      onKeyDown={onKeyDown}
      value={value}
      options={options}
      isOptionEqualToValue={(option, value) => option.nome === value}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={label}
          name={name}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                style={{ marginRight: 7, paddingLeft: 5 }}
                position="start"
              >
                {icon}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default DefaultAutocomplete;
