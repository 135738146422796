import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CheckBox, Close } from "@mui/icons-material";

export const DefaultDropDownWithCheckBox = ({
  title,
  variant = "contained",
  data = [],
  onSelect = () => {},
}) => {
  const [_data, setData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = anchorEl;
  const handleClick = (event) => {
    if (!data.length) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setData(data);
  }, [data]);

  return (
    <div>
      <Button
        variant={variant}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {_data.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              onSelect({ ...item, index });
              e.stopPropagation();
              // handleClose();
            }}
          >
            {item.checked ? (
              <CheckBox color="primary" />
            ) : (
              <Close color="error" />
            )}
            {item.title || item.nome}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const DefaultDropDown = ({
  title,
  variant = "contained",
  data = [],
  onSelect = () => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = anchorEl;
  const handleClick = (event) => {
    if (!data.length) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant={variant}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {data.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onSelect(item);
              handleClose();
            }}
          >
            {item.title || item.nome}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DefaultDropDown;
