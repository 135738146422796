//COMPONENTES
import PaginaInicio from "../Pages/Inicio";

//ICONES
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Login from "../Pages/Login";
import PesquisaDePrecos from "../Pages/PesquisaDePrecos";
import Manuais from "../Pages/Manuais";
import ExportarPesquisas from "../Pages/Exportar";
import LandingPage from "../Site";

const Routes = [
  {
    path: "/",
    name: "Site",
    element: <LandingPage />,
  },
  {
    path: "/login",
    name: "Login",
    element: <Login />,
  },
  {
    path: "/inicio",
    exact: true,
    name: "Inicio",
    icon: <HomeIcon />,
    element: <PaginaInicio />,
  },
  {
    path: "/exportar-pesquisa",
    exact: true,
    name: "Pesquisa de preços",
    icon: <SearchIcon />,
    element: <ExportarPesquisas />,
  },
  {
    path: "/manuais",
    exact: true,
    name: "Manuais",
    icon: <AssignmentIcon />,
    element: <Manuais />,
  },
  // {
  //   path: "/exportar-pesquisa",
  //   exact: true,
  //   name: "Exportar pesquisa",
  //   icon: <AssignmentIcon />,
  //   element: <ExportarPesquisas />,
  // },
];

export default Routes;
