import * as React from "react";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Chip,
  Tab,
} from "@mui/material";
import {
  Map,
  LocationCity,
  Gavel,
  AttachMoney,
  Factory,
  Info,
  AddRoad,
  Numbers,
  LocationSearching,
  AlternateEmail,
  Person,
} from "@mui/icons-material";

import DefaultDatePicker from "../DefaultDatePicker";
import { formatted, getBase64, resizeImage, UFBR } from "../../Utils";
import {
  buscarCep,
  getClientes,
  novoUsuario,
  updateClientes,
} from "../../Services";
import moment from "moment";
import DefaultModal from "../DefaultModal";
import "./style.css";
import LoadingButton from "../LoadingButton";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DefaultAutocomplete from "../Autocomplete";

const DADOS = [
  {
    name: "orgao",
    label: "Órgão",
    icon: Gavel,
    type: "text",
    size: 6,
  },
  {
    name: "razao_social",
    label: "Razão Social",
    icon: Factory,
    type: "text",
    size: 6,
  },
  {
    name: "cnpj",
    label: "CNPJ",
    icon: Info,
    type: "text",
    size: 6,
  },
  {
    name: "cep",
    label: "CEP",
    icon: Map,
    type: "text",
    size: 6,
  },
  {
    name: "logradouro",
    label: "Logradouro",
    icon: AddRoad,
    type: "text",
    size: 9,
  },
  { name: "numero", label: "Nº", icon: Numbers, type: "text", size: 3 },
  {
    name: "municipio",
    label: "Município",
    icon: LocationCity,
    type: "text",
    size: 4,
  },
  {
    name: "sigla_uf",
    label: "UF",
    icon: LocationSearching,
    options: UFBR,
    type: "select",
    size: 2,
  },
  {
    name: "prazo_contrato",
    label: "Validade do contrato",
    icon: AttachMoney,
    type: "date",
    size: 3,
  },
  {
    name: "user_adm",
    label: "Tipo de usuário",
    icon: Person,
    type: "select",
    options: [
      { sigla: "Administrador", nome: "Administrador" },
      { sigla: "Usuário", nome: "Usuário" },
    ],
    size: 3,
  },
  {
    name: "email",
    label: "Email(s)",
    icon: AlternateEmail,
    type: "tags",
    size: 12,
  },
];

const ModalNewuser = ({ open, onClose, setNotification, adm }) => {
  if (!adm) {
    return null;
  }
  const [dados, setDados] = React.useState({
    prazo_contrato: moment().add(1, "year"),
    email: [],
  });
  const [dadosEditar, setDadosEditar] = React.useState({
    prazo_contrato: moment().add(1, "year"),
    orgao: "",
    email: [],
  });
  const [tab, setTab] = React.useState("1");
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setDados({
      prazo_contrato: moment().add(1, "year"),
      email: [],
    });
    setDadosEditar({
      prazo_contrato: moment().add(1, "year"),
      orgao: "",
      email: [],
    });
    setTab("1");
    onClose();
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;

    if (name === "cnpj") {
      value = formatted.cnpjCpfMask(value);
    }

    if (name === "cep") {
      value = formatted.cep(value);

      if (value.length === 10) {
        let _cep = formatted.numero(value);
        const res = await buscarCep(_cep);
        if (res.success) {
          let { logradouro, localidade, uf } = res.data;

          setDados({
            ...dados,
            [name]: value,
            logradouro: logradouro,
            municipio: localidade,
            sigla_uf: uf,
          });
          return;
        }
      }
    }

    setDados({ ...dados, [name]: value });
  };

  const changeEditar = async (e) => {
    let { name, value } = e.target;

    if (value.length > 60) {
      return;
    }

    if (name === "cnpj") {
      value = formatted.cnpjCpfMask(value);
    }

    if (name === "cep") {
      value = formatted.cep(value);

      if (value.length === 10) {
        let _cep = formatted.numero(value);
        const res = await buscarCep(_cep);
        if (res.success) {
          let { logradouro, localidade, uf } = res.data;

          setDadosEditar({
            ...dadosEditar,
            [name]: value,
            logradouro: logradouro,
            municipio: localidade,
            sigla_uf: uf,
          });
          return;
        }
      }
    }

    setDadosEditar({ ...dadosEditar, [name]: value });
  };

  const hadleChangeTab = (e, v) => setTab(v);

  const handleChangeData = (e) => {
    setDados({ ...dados, prazo_contrato: e });
  };

  const handleChangeDataEditar = (e) => {
    setDadosEditar({ ...dadosEditar, prazo_contrato: e });
  };

  const handleSave = async () => {
    let _data =
      tab === "2" ? JSON.stringify(dadosEditar) : JSON.stringify(dados);
    _data = JSON.parse(_data);
    for (let index = 0; index < DADOS.length; index++) {
      const { name, label } = DADOS[index];
      if (!_data[name]) {
        setNotification({
          message: `Para prosseguir preencha o campo ${label}`,
          open: true,
          severity: "warning",
        });
        return;
      }
    }

    _data.user_adm = _data.user_adm === "Administrador";
    _data.cep = formatted.numero(_data.cep);
    _data.nome_uf = UFBR.filter((i) => i.sigla === _data.sigla_uf)[0].nome;

    setLoading(true);
    const res =
      tab === "2" ? await updateClientes(_data) : await novoUsuario(_data);

    if (res.success) {
      setNotification({
        message: `Cliente ${
          tab === "2" ? "atualizado" : "criado"
        } com sucesso, senha enviada para os emails informados`,
        open: true,
        severity: "success",
      });
      onClose();
      setDados({});
    } else {
      setNotification({
        message: res.msg,
        open: true,
        severity: "error",
      });
    }
    setLoading(false);
  };

  const buscarCliente = async () => {
    setOptions([]);
    const res = await getClientes(dadosEditar.orgao);

    if (res.success) {
      setOptions(res.data);
    }
  };

  const selecionarLogo = async (e, editar = false) => {
    e.preventDefault();
    const accept = ["image/jpeg", "image/png", "image/jpg"];
    const { name, files } = e.target;

    if (!files.length) {
      return;
    }
    const file = files.item(0);

    const v = file.value;

    // if (accept.every((i) => i !== file.type)) {
    //   setNotification({
    //     message: "Atenção! Somente serão aceitos arquivos jpeg, jpg e png",
    //     open: true,
    //     severity: "warning",
    //   });
    //   return;
    // }

    // if (file.size > 50000) {
    //   setNotification({
    //     message: "Atenção! O logo deve ter no máximo 50KB",
    //     open: true,
    //     severity: "warning",
    //   });
    //   return;
    //   return;
    // }

    let b64 = await getBase64(file);

    b64 = await resizeImage(b64);

    if (editar) {
      setDadosEditar({ ...dadosEditar, [name]: b64 });
    } else {
      setDados({
        ...dados,
        [name]: b64,
      });
    }
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      buscarCliente();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [dadosEditar.orgao]);

  return (
    <DefaultModal
      title="Cadastro de usuários"
      open={open}
      onClose={handleClose}
      children={
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={hadleChangeTab}>
              <Tab label="Cadastrar" value="1" />
              <Tab label="Editar" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={4}>
              <Grid item>
                <label>
                  <input
                    type="file"
                    className="upload-button"
                    name={"brasao"}
                    onChange={selecionarLogo}
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <Avatar
                    sx={{
                      width: 106,
                      height: 106,
                    }}
                    imgProps={{ style: { objectFit: "contain" } }}
                    src={dados?.brasao}
                  />
                </label>
              </Grid>
              <Grid item xs={10} container spacing={2}>
                {DADOS.map((item, index) => {
                  if (item.type === "text") {
                    return (
                      <Grid item key={index} xs={item.size}>
                        <TextField
                          className="default-input-padding"
                          variant="outlined"
                          size="small"
                          fullWidth
                          label={item.label}
                          name={item.name}
                          type={item.type}
                          value={dados?.[item.name] || ""}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                style={{ marginRight: 7 }}
                                position="start"
                              >
                                <item.icon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    );
                  } else if (item.type === "select") {
                    return (
                      <Grid item xs={item.size} key={index}>
                        <TextField
                          size="small"
                          fullWidth
                          select
                          label={item.label}
                          name={item.name}
                          onChange={handleChange}
                          value={dados?.[item.name] || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                style={{ marginRight: 7 }}
                                position="start"
                              >
                                <item.icon />
                              </InputAdornment>
                            ),
                          }}
                        >
                          {item.options.map((i, _index) => (
                            <MenuItem key={_index} value={i.sigla}>
                              {i.sigla}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    );
                  } else if (item.type === "date") {
                    return (
                      <Grid item xs={item.size} key={index}>
                        <DefaultDatePicker
                          style={{ width: "100%" }}
                          label={item.label}
                          onChange={handleChangeData}
                          value={dados?.[item.name]}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={item.size} key={index}>
                        <Autocomplete
                          multiple
                          options={[]}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          value={dados.email}
                          onChange={(e, v, type) => {
                            if (v.every((i) => formatted.isEmail(i))) {
                              setDados({ ...dados, email: v });
                            } else {
                              setNotification({
                                message: `Insira um email válido!`,
                                open: true,
                                severity: "warning",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="default-input-padding"
                              variant="outlined"
                              size="small"
                              fullWidth
                              label={item.label}
                            />
                          )}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={3}>
                <LoadingButton
                  onClick={handleSave}
                  loading={loading}
                  fullWidth
                  variant="contained"
                >
                  Salvar
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={onClose}
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container spacing={4}>
              <Grid item>
                <label>
                  <input
                    type="file"
                    className="upload-button"
                    name={"brasao"}
                    onChange={(e) => selecionarLogo(e, true)}
                    accept="image/jpg, image/png, image/jpeg"
                    disabled={!dadosEditar._id}
                  />
                  <Avatar
                    sx={{ width: 106, height: 106 }}
                    imgProps={{ style: { objectFit: "contain" } }}
                    src={dadosEditar?.brasao}
                  />
                </label>
              </Grid>
              <Grid item xs={10} container spacing={2}>
                {DADOS.map((item, index) => {
                  if (item.type === "text" && item.name !== "orgao") {
                    return (
                      <Grid item key={index} xs={item.size}>
                        <TextField
                          className="default-input-padding"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled={item.name === "cnpj"}
                          label={item.label}
                          name={item.name}
                          type={item.type}
                          value={dadosEditar?.[item.name] || ""}
                          onChange={changeEditar}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                style={{ marginRight: 7 }}
                                position="start"
                              >
                                <item.icon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    );
                  } else if (item.type === "select") {
                    return (
                      <Grid item xs={item.size} key={index}>
                        <TextField
                          size="small"
                          fullWidth
                          select
                          label={item.label}
                          name={item.name}
                          onChange={changeEditar}
                          value={dadosEditar?.[item.name] || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                style={{ marginRight: 7 }}
                                position="start"
                              >
                                <item.icon />
                              </InputAdornment>
                            ),
                          }}
                        >
                          {item.options.map((i, _index) => (
                            <MenuItem key={_index} value={i.sigla}>
                              {i.sigla}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    );
                  } else if (item.type === "date") {
                    return (
                      <Grid item xs={item.size} key={index}>
                        <DefaultDatePicker
                          style={{ width: "100%" }}
                          label={item.label}
                          onChange={handleChangeDataEditar}
                          value={dadosEditar?.[item.name]}
                        />
                      </Grid>
                    );
                  } else if (item.type === "tags") {
                    return (
                      <Grid item xs={item.size} key={index}>
                        <Autocomplete
                          multiple
                          options={[]}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          value={dadosEditar.email}
                          onChange={(e, v, type) => {
                            if (v.every((i) => formatted.isEmail(i))) {
                              setDadosEditar({ ...dadosEditar, email: v });
                            } else {
                              setNotification({
                                message: `Insira um email válido!`,
                                open: true,
                                severity: "warning",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="default-input-padding"
                              variant="outlined"
                              size="small"
                              fullWidth
                              label={item.label}
                            />
                          )}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={item.size} key={index}>
                        <DefaultAutocomplete
                          key={dadosEditar.email.length}
                          label={item.label}
                          name={item.name}
                          value={dadosEditar?.[item.name]}
                          onInputChange={changeEditar}
                          onChange={(e) => {
                            const { objeto } = e.target;
                            const retorno = objeto || {
                              prazo_contrato: moment().add(1, "year"),
                              orgao: "",
                              email: [],
                            };
                            setDadosEditar(retorno);
                          }}
                          options={options}
                          onKeyDown={() => {}}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={3}>
                <LoadingButton
                  onClick={handleSave}
                  loading={loading}
                  fullWidth
                  variant="contained"
                >
                  Salvar
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={onClose}
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      }
    />
  );
};

export default ModalNewuser;
