import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "../../components/AppBar";
import TemporaryDrawer, { fonte } from "../../components/DrawerPesquisar";
import DataTable from "../../components/Table";
import { consultarBanco } from "../../Services";
import moment from "moment";
import AddNotification from "../../components/Snackbars";

const ExportarPesquisas = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState({ size: 100, number: 0, count: 1 });
  const [notification, setNotification] = useState({
    message: "",
    open: false,
    severity: "",
    action: {},
  });
  const [clearHeader, setClearHeader] = useState(false);

  const onPageChange = (e) => setPage({ ...page, number: e });

  const handlePageSize = (e) => {
    setPage({ ...page, size: e, number: 0 });
  };

  const filtrarConsulta = async (e, usePage = false) => {
    if (loading) {
      return;
    }
    let _data = Object.assign({}, e);

    if (_data?.matServ.length < 4) {
      setNotification({
        message:
          "Digite ao menos três letras para realizar a busca de material ou serviço!",
        open: true,
        severity: "warning",
      });
      return;
    }

    setLoading(true);

    if (Object.values(_data).length < 1) {
      setLoading(false);
      return;
    }

    _data.dataInicial = _data.dataInicial
      ? moment(_data.dataInicial).format("YYYY-MM-DD")
      : moment().subtract(12, "months").format("YYYY-MM-DD");
    _data.dataFinal = moment(_data.dataFinal).format("YYYY-MM-DD");
    _data.registros = page.size;
    _data.pagina = usePage ? page.number : 0;

    if (_data?.valorMin) {
      if (isNaN(_data?.valorMin))
        _data.valorMin = Number(
          _data.valorMin.replace("R$", "").replaceAll(".", "").replace(",", ".")
        );
    }

    if (_data?.valorMax) {
      if (isNaN(_data?.valorMax))
        _data.valorMax = Number(
          _data.valorMax.replace("R$", "").replaceAll(".", "").replace(",", ".")
        );
    }

    const res = await consultarBanco(_data);
    setLoading(false);

    if (res.success) {
      setData(res.data);
      setPage((page) => ({
        ...page,
        count: res.registros,
        number: usePage ? page.number : 0,
      }));
    } else {
      setData([]);
      setPage((page) => ({ ...page, count: 0, number: 0 }));
    }
    setFiltros(_data);
  };

  const handleNotification = (message, open, severity, action = null) => {
    setNotification({
      message: message,
      open: open,
      severity: severity,
      action: action,
    });
  };

  useEffect(() => {
    filtrarConsulta(filtros, true);
  }, [page.number, page.size]);

  return (
    <>
      <AddNotification
        notification={notification}
        setNotification={setNotification}
      />
      <ResponsiveAppBar />
      <div className="container-pesquisa-precos">
        <TemporaryDrawer
          getData={filtrarConsulta}
          open={open}
          setOpen={setOpen}
          // clearHeader={() => setClearHeader(true)}
        />
        <div className="inner-container-pesquisa-precos">
          <DataTable
            data={data}
            setData={setData}
            loading={loading}
            addNotification={handleNotification}
            openPesquisar={open}
            page={page}
            filters={filtros}
            handlePageSize={handlePageSize}
            onPageChange={onPageChange}
            clearHeader={clearHeader}
            setClearHeader={setClearHeader}
          />
        </div>
      </div>
    </>
  );
};

export default ExportarPesquisas;
