import * as React from "react";
import { useNavigate } from "react-router-dom";

import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

import Routes from "../../Routes";

//style
import "./style.css";

const drawerWidth = 260;

const removerDoMenu = ["/", "/login"];

export default function Sidebar() {
  const navigate = useNavigate();

  const handleNavigate = (route) => navigate(route);

  return (
    <Drawer
      className="sidebar"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },

        border: "none",
      }}
      PaperProps={{
        sx: { border: "none", backgroundColor: "#1976d2" },
      }}
      variant="permanent"
      anchor="left"
      elevation={6}
    >
      <Toolbar />
      <List>
        {Routes.filter((i) => !removerDoMenu.includes(i.path)).map(
          (item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                selected={item.path === "/inicio"}
                onClick={() => handleNavigate(item.path)}
              >
                <ListItemIcon sx={{ color: "#fff" }}>{item.icon}</ListItemIcon>
                <ListItemText sx={{ color: "#fff" }}>
                  <Typography
                    noWrap
                    sx={{
                      fontWeight: 500,
                      letterSpacing: ".05rem",
                      color: "inherit",
                    }}
                  >
                    {item.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Drawer>
  );
}
