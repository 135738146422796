import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css";
import ResponsiveAppBar from "../../components/AppBar";
import AddNotification from "../../components/Snackbars";

const PesquisaDePrecos = () => {
  const [pesquisa, setPesquisa] = useState("");
  const [notification, setNotification] = useState({
    message: "",
    open: false,
    severity: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => setPesquisa(e.target.value);

  const handlePesquisar = () => {
    if (pesquisa.length === 0) {
      setNotification({
        open: true,
        message: "Preencha o campo de pesquisa",
        severity: "warning",
      });
      return;
    }
    sessionStorage.setItem("matServ", pesquisa);
    navigate("/exportar-pesquisa");
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      sessionStorage.setItem("matServ", pesquisa);
      handlePesquisar();
    }
  };

  return (
    <>
      <AddNotification
        notification={notification}
        setNotification={setNotification}
      />
      <ResponsiveAppBar />
      <div className="container-pesquisa-precos">
        <div className="inner-container-pesquisa-precos">
          <Stack
            className="search-image"
            spacing={{ xs: 1, sm: 2, md: 1 }}
            sx={{ width: 600, textAlign: "center", marginTop: "5%" }}
          >
            <Typography variant="subtitle1">
              Informe qual o material ou o serviço que você deseja pesquisar
            </Typography>
            <TextField
              placeholder="Pesquisar por nome do material ou serviço"
              onKeyDown={handlePressEnter}
              onChange={handleChange}
              InputProps={{
                style: { borderRadius: 30 },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={handlePesquisar}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default PesquisaDePrecos;
