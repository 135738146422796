import React from "react";
import { Button, CircularProgress } from "@mui/material";

const LoadingButton = ({ loading, loadingVariant, children, ...props }) => {
  return (
    <Button disabled={loading} {...props}>
      {loading ? (
        <CircularProgress size={24} variant={loadingVariant} />
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
