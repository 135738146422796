import { Box, Typography } from "@mui/material";
import React from "react";
import Sidebar from "../../components/Sidebar";

//styles
import "./style.css";

const PaginaInicio = () => {
  return (
    <div className="container-inicio">
      <Sidebar />
      <div className="page-box">
        <Box
          className="welcome-image"
          sx={{ textAlign: "center", marginTop: "8%" }}
        >
          <Typography variant="h4">
            Seja bem vindo ao Banco de Preços
          </Typography>
          <Typography>
            Clique em <b style={{ color: "#1976d2 " }}>Pesquisa de preços</b>{" "}
            para começar!
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default PaginaInicio;
