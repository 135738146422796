import * as React from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalNewuser from "../ModalNewUser";
import AddNotification from "../Snackbars";
import ModalAtualizarBanco from "../ModalAtualizarBanco.js";
import ModalAtualizaPNCP from "../ModalAtualizarPNCP/index.js";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isAdm, setAdm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [notification, setNotification] = React.useState({
    message: "",
    open: false,
    severity: "",
  });
  const [atualizarBanco, setAtualizarBanco] = React.useState(false);
  const [brasao, setBrasao] = React.useState("");
  const [modalPNCP, setModalPNCP] = React.useState(false);
  const navigate = useNavigate();

  const handleOpenAtualizarBanco = () => setAtualizarBanco(true);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/", { replace: true });
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const admin = sessionStorage.getItem("isAdm") === "true";
    const _user = sessionStorage.getItem("user");
    const brasao = sessionStorage.getItem("brasao");

    setAdm(admin);
    setUser(_user);
    setBrasao(brasao);
  });

  return (
    <AppBar position="static" style={{ height: 60 }}>
      <AddNotification
        notification={notification}
        setNotification={setNotification}
      />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="span"
            noWrap
            component="a"
            onClick={() => navigate("/inicio")}
            sx={{
              mr: 2,
              ml: 1,
              display: "flex",
              fontWeight: 450,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ArrowBackIcon sx={{ mr: 1 }} />
            VOLTAR
          </Typography>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Typography
              variant="h6"
              noWrap
              sx={{
                ml: 1,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                alignItems: "center",
              }}
            >
              PESQUISAS
            </Typography>
            <Typography
              //   variant="h6"
              noWrap
              sx={{
                mr: 2,
                ml: 1,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                alignItems: "center",
              }}
            >
              {"> MATERIAIS/SERVIÇOS"}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0, display: "flex" }}>
            <div>
              <Stack sx={{ textAlign: "right" }}>
                <Typography
                  //   variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    ml: 1,
                    display: { xs: "none", md: "flex" },
                    fontWeight: 700,
                    letterSpacing: ".1rem",
                    color: "inherit",
                    textDecoration: "none",
                    justifyContent: "flex-end",
                  }}
                >
                  Usuário
                </Typography>
                <Typography
                  variant="caption"
                  noWrap
                  sx={{
                    mr: 2,
                    ml: 1,
                    display: { xs: "none", md: "flex" },
                    fontWeight: 700,
                    letterSpacing: ".1rem",
                    color: "inherit",
                    textDecoration: "none",
                    justifyContent: "flex-end",
                  }}
                >
                  {user}
                </Typography>
              </Stack>
            </div>
            <Tooltip title="Abrir configurções">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user.length > 0 ? user : "D"}
                  sx={{ height: 45, width: 45 }}
                  imgProps={{ style: { objectFit: "contain" } }}
                  src={brasao || "/static/images/avatar/2.jpg"}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isAdm && (
                <MenuItem onClick={handleOpen}>
                  <Typography textAlign="center">
                    Cadastro de clientes
                  </Typography>
                </MenuItem>
              )}
              {isAdm && (
                <MenuItem onClick={handleOpenAtualizarBanco}>
                  <Typography textAlign="center">
                    Atualizar banco de dados
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={() => setModalPNCP(!modalPNCP)}>
                <Typography textAlign="center">Atualizar PNCP</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Sair</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <ModalNewuser
        adm={isAdm}
        open={open}
        onClose={handleClose}
        setNotification={setNotification}
      />
      <ModalAtualizarBanco
        adm={isAdm}
        open={atualizarBanco}
        onClose={() => setAtualizarBanco(false)}
        setNotification={setNotification}
      />
      <ModalAtualizaPNCP
        open={modalPNCP}
        onClose={() => setModalPNCP(false)}
        setNotification={setNotification}
      />
    </AppBar>
  );
};
export default ResponsiveAppBar;
