import React from "react";

const Manuais = () => {
  return (
    <div>
      <h1>Manuais</h1>
    </div>
  );
};

export default Manuais;
