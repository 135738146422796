// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/back-login.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-container {\n  height: 100vh;\n  width: 100vw;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #bbdefb;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  /* border-image-width: 100vw; */\n  /* background-position-y: -50px; */\n}\n", "",{"version":3,"sources":["webpack://./src/Pages/Login/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,yDAAyD;EACzD,4BAA4B;EAC5B,sBAAsB;EACtB,+BAA+B;EAC/B,kCAAkC;AACpC","sourcesContent":[".login-container {\n  height: 100vh;\n  width: 100vw;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #bbdefb;\n  background-image: url(\"../../assets/img/back-login.jpeg\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  /* border-image-width: 100vw; */\n  /* background-position-y: -50px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
