import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Grid, TextField } from "@mui/material";
import { formatted } from "../../Utils";
import { buscarCep, getUasgs, integracaoJSON } from "../../Services";
import DefaultModal from "../DefaultModal";
import LoadingButton from "../LoadingButton";
import DefaultAutocomplete from "../Autocomplete";

const UASG = {
  _id: null,
  nome: "",
  cep: "",
  logradouro: "",
  municipio: "",
  sigla_uf: "",
  numero: "",
  complemento: "",
  bairro: "",
  link: "",
};

const ModalAtualizarBanco = ({ open, onClose, setNotification, adm }) => {
  if (!adm) {
    return null;
  }
  const [loading, setLoading] = React.useState(false);
  const [uasg, setUasg] = React.useState(UASG);
  const [options, setOptions] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [novaUasg, setNovaUasg] = React.useState(false);

  const handleChange = async (e) => {
    let { name, value } = e.target;

    if (name === "cep") {
      value = formatted.cep(value);

      if (value.length === 10) {
        let _cep = formatted.numero(value);
        const res = await buscarCep(_cep);
        if (res.success) {
          let { logradouro, localidade, uf, bairro } = res.data;

          setUasg({
            ...uasg,
            [name]: value,
            logradouro: logradouro,
            municipio: localidade,
            sigla_uf: uf,
            bairro: bairro,
          });
          return;
        }
      }
    }

    if (name === "numero") {
      value = formatted.numero(value);
    }

    if (name === "nome") {
      setUasg((old) => ({ ...old, _id: e.target.id }));
    }

    setUasg((old) => ({ ...old, [name]: value }));
  };

  const handleClose = () => {
    onClose();
    setNovaUasg(false);
    setUasg(UASG);
    setFile(null);
  };

  const handleSave = async () => {
    let verificar = Object.keys(uasg).filter(
      (i) => i !== "complemento" && i !== "_id"
    );

    if (!uasg._id) {
      for (let index = 0; index < verificar.length; index++) {
        const element = verificar[index];
        if (!uasg[element]) {
          setNotification({
            message: `Para prosseguir preencha o campo ${element}`,
            open: true,
            severity: "warning",
          });
          return;
        }
      }
    }

    if (!file) {
      setNotification({
        message: `Para prosseguir selecione um arquivo CSV`,
        open: true,
        severity: "warning",
      });
      return;
    }

    setLoading(true);
    const res = await integracaoJSON(uasg, file);
    setLoading(false);
    handleClose();

    if (res.success) {
      setNotification({
        message: `Integração iniciada com sucesso, um email será enviado para contato.precospublicosonline@gmail.com quando o processo finalizar.`,
        open: true,
        severity: "success",
      });
    } else {
      setNotification({
        message: res.message,
        open: true,
        severity: "error",
      });
    }
  };

  const buscarUasgs = async () => {
    const res = await getUasgs(uasg.nome);

    if (res.success) {
      setOptions(res.data);
    }
  };

  const handleSelect = (e) => {
    setFile(e.target.files[0]);
  };

  React.useEffect(() => {
    buscarUasgs();
  }, [uasg.nome]);

  return (
    <DefaultModal
      title="Atualizar banco de dados"
      open={open}
      onClose={onClose}
    >
      <Box sx={{ width: "100%", typography: "body1", marginTop: 8 }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <DefaultAutocomplete
              label={"Nome orgão"}
              name={"nome"}
              value={uasg.nome}
              renderButton
              onChange={handleChange}
              onInputChange={handleChange}
              options={options}
              renderButtonFunc={() => {
                setNovaUasg(true);
                setUasg({ ...uasg, _id: null });
              }}
              onKeyDown={() => {}}
            />
          </Grid>
          {novaUasg ? (
            <>
              <Grid item xs={2}>
                <TextField
                  label={"Cep"}
                  size="small"
                  name="cep"
                  value={uasg.cep}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={"Logradouro"}
                  size="small"
                  name="logradouro"
                  value={uasg.logradouro}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label={"Número"}
                  size="small"
                  value={uasg.numero}
                  fullWidth
                  name="numero"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={"Complemento"}
                  size="small"
                  name="complemento"
                  value={uasg.complemento}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={"Bairro"}
                  size="small"
                  name="bairro"
                  value={uasg.bairro}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={"Cidade"}
                  size="small"
                  name="municipio"
                  value={uasg.municipio}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={"UF"}
                  value={uasg.sigla_uf}
                  size="small"
                  name="sigla_uf"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={5}>
            <TextField
              label={"Link"}
              size="small"
              name="link"
              type="text"
              value={uasg.link}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <input
              type="file"
              name="file"
              onChange={handleSelect}
              accept="application/csv"
            />
          </Grid>

          <Grid item xs={6} />

          <Grid item xs={3}>
            <LoadingButton
              onClick={handleSave}
              loading={loading}
              fullWidth
              variant="contained"
            >
              Salvar
            </LoadingButton>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => {
                setNovaUasg(false);
                setUasg(UASG);
              }}
              fullWidth
              variant="contained"
              color="error"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DefaultModal>
  );
};

export default ModalAtualizarBanco;
