import React, { memo } from "react";
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";

const DefaultModal = ({ children, open = false, onClose, title, style }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
      sx={{ overflow: "hidden" }}
    >
      <Box className="container-modal" style={style}>
        <Box className="header-modal">
          <Typography component="h1">
            <b className="title-modal">{title}</b>
          </Typography>
          <Tooltip title={"Fechar"} placement="right">
            <IconButton onClick={onClose} className="close-modal">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="body-modal">{children}</Box>
      </Box>
    </Modal>
  );
};

export default DefaultModal;
