import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Grid, TextField } from "@mui/material";
import DefaultModal from "../DefaultModal";
import LoadingButton from "../LoadingButton";
import { intergracaoPNCP } from "../../Services";

const ModalAtualizaPNCP = ({ open, onClose, setNotification }) => {
  const [contratoid, setContratoid] = React.useState("");
  const [dadosRetorno, setDadosRetorno] = React.useState({
    itemCriado: 0,
    itemNaoEncontrado: [],
  });
  const [loading, setLoading] = React.useState(false);

  const handleSave = async () => {
    if (loading) {
      return;
    }

    if (!contratoid) {
      setNotification({
        message: "Atenção! informe o Id contratação PNCP",
        open: true,
        severity: "info",
      });
      return;
    }

    setLoading(true);
    const res = await intergracaoPNCP(contratoid);
    setLoading(false);

    if (res.success) {
      if (typeof res.data === "string") {
        setNotification({ message: res.data, open: true, severity: "success" });
      } else {
        setNotification({
          message: "Antenção! Nem todos os itens tinham resultados no PNCP.",
          open: true,
          severity: "info",
        });
        setDadosRetorno(res.data);
      }
    } else {
      setNotification({
        message: res.message,
        open: true,
        severity: "warning",
      });
    }
  };

  const clear = () => {
    if (loading) {
      setNotification({
        message: "Aguarde a atualização terminar",
        open: true,
        severity: "info",
      });
      return;
    }
    setContratoid("");
    setDadosRetorno({
      itemCriado: 0,
      itemNaoEncontrado: [],
    });
    setLoading(false);

    onClose();
  };

  return (
    <DefaultModal title="Atualizar dados PNCP" open={open} onClose={clear}>
      <Box sx={{ width: "100%", typography: "body1", marginTop: 8 }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Id contratação PNCP"
              value={contratoid}
              onChange={(e) => setContratoid(e.target.value)}
            />
          </Grid>

          {dadosRetorno.itemNaoEncontrado.length ? (
            <>
              <Grid item xs={12}>
                <h5 style={{ display: "flex", gap: 10 }}>
                  <span>
                    Total de itens:{" "}
                    {dadosRetorno.itemCriado +
                      dadosRetorno.itemNaoEncontrado.length}
                  </span>
                  <span>Itens criados: {dadosRetorno.itemCriado}</span>
                  <span>
                    Itens sem resultados:{" "}
                    {dadosRetorno.itemNaoEncontrado.length}
                  </span>
                </h5>
                <h5>Descricao itens sem resultado:</h5>
                <div
                  style={{
                    maxHeight: 400,
                    overflowY: "scroll",
                    fontSize: "0.8rem",
                  }}
                >
                  <ul>
                    {dadosRetorno.itemNaoEncontrado.map((p, index) => (
                      <li key={index}>{p}</li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={3}>
                <LoadingButton
                  onClick={() =>
                    setDadosRetorno({ itemCriado: 0, itemNaoEncontrado: [] })
                  }
                  loading={loading}
                  fullWidth
                  variant="contained"
                >
                  Voltar
                </LoadingButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3}>
                <LoadingButton
                  onClick={handleSave}
                  loading={loading}
                  fullWidth
                  variant="contained"
                >
                  Atualizar
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={clear}
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Cancelar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </DefaultModal>
  );
};

export default ModalAtualizaPNCP;
