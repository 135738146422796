import Routes from "./Routes";
import {
  BrowserRouter,
  Routes as Switch,
  Route,
  Navigate,
  redirect,
} from "react-router-dom";
import "./assets/css/global.css";
import { useEffect, useState } from "react";
import { validateUser } from "./Services";
import Login from "./Pages/Login";
import LandingPage from "./Site";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const authUser = async () => {
    const res = await validateUser();
    setIsAuthenticated(res.success);
  };

  useEffect(() => {
    authUser();
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="*" element={<Navigate to={"/"} />} replace />
        {isAuthenticated ? (
          <>
            {Routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={route.element}
              />
            ))}
          </>
        ) : (
          <>
            <Route element={<LandingPage />} path="/" />
            <Route
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
              path="/login"
            />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};
export default App;
