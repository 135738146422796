import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//material components
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Link,
  Typography,
} from "@mui/material";

//icons
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// style
import "./style.css";
import AddNotification from "../../components/Snackbars";
import LoadingButton from "../../components/LoadingButton";
import { resetSenha, userLogin } from "../../Services";

const Login = ({ setIsAuthenticated = () => {} }) => {
  const [dados, setDados] = useState({ password: "", email: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [recuperarSenha, setRecuperarSenha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: "",
    open: false,
    severity: "",
  });

  const navigate = useNavigate();

  const toggleShowpassword = () => setShowPassword(!showPassword);

  const toggleRecuperarSenha = () => setRecuperarSenha(!recuperarSenha);

  const handleSubmit = async (e) => {
    setLoading(true);
    if (recuperarSenha) {
      const res = await resetSenha(dados);

      if (res.success) {
        setNotification({
          message: "Uma nova senha foi enviada para o seu email!",
          open: true,
          severity: "success",
        });

        setRecuperarSenha(false);
      } else {
        setNotification({
          open: true,
          message: res.msg,
          severity: "error",
        });
      }
    } else {
      const res = await userLogin(dados);
      if (res.success) {
        setNotification({
          open: true,
          message: "Login realizado com sucesso",
          severity: "success",
        });

        setIsAuthenticated(true);
        navigate("/inicio", { replace: true });
      } else {
        setIsAuthenticated(false);
        setNotification({
          open: true,
          message: res.msg,
          severity: "error",
        });
      }
    }
    setLoading(false);
  };

  const handleChange = (e) =>
    setDados({ ...dados, [e.target.name]: e.target.value });

  return (
    <div className="login-container">
      <AddNotification
        notification={notification}
        setNotification={setNotification}
      />
      <Card sx={{ minWidth: 400, padding: 2 }}>
        <CardHeader
          title={
            <Typography variant="h5">Acesso a Área do Servidor</Typography>
          }
          sx={{ textAlign: "center" }}
        />
        <CardContent>
          <Stack spacing={{ xs: 1, sm: 2, md: 2 }}>
            {!recuperarSenha ? (
              <>
                <TextField
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">@</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="password"
                  onChange={handleChange}
                  placeholder="Senha"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowpassword}>
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  name="email"
                  onChange={handleChange}
                  placeholder="Informe seu e-mail"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">@</InputAdornment>
                    ),
                  }}
                />
              </>
            )}
            <Link
              onClick={toggleRecuperarSenha}
              sx={{ textAlign: "end", cursor: "pointer" }}
            >
              {recuperarSenha
                ? "Voltar para tela de login"
                : "Esqueceu a senha?"}
            </Link>
            <LoadingButton
              loading={loading}
              onClick={handleSubmit}
              variant="contained"
              color={recuperarSenha ? "secondary" : "primary"}
              loadingVariant={recuperarSenha ? "secondary" : "primary"}
              sx={{ height: "50px" }}
            >
              {recuperarSenha ? "Enviar senha para o meu email" : "Entrar"}
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
