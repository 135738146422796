// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/welcome-img.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-inicio {\n  height: 100vh;\n  background-color: #f2f2f2;\n}\n\n.box-boas-vindas {\n  position: absolute;\n  top: 10%;\n  right: calc(50% - 240px);\n  transform: translateX(calc(50% - 240px));\n}\n\n.page-box {\n  position: absolute;\n  left: 240px;\n  height: inherit;\n  width: calc(100% - 240px);\n  display: flex;\n  justify-content: center;\n}\n\n.welcome-image {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position-y: 100px;\n}\n", "",{"version":3,"sources":["webpack://./src/Pages/Inicio/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,wBAAwB;EACxB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,yBAAyB;EACzB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yDAAyD;EACzD,wBAAwB;EACxB,4BAA4B;EAC5B,4BAA4B;AAC9B","sourcesContent":[".container-inicio {\n  height: 100vh;\n  background-color: #f2f2f2;\n}\n\n.box-boas-vindas {\n  position: absolute;\n  top: 10%;\n  right: calc(50% - 240px);\n  transform: translateX(calc(50% - 240px));\n}\n\n.page-box {\n  position: absolute;\n  left: 240px;\n  height: inherit;\n  width: calc(100% - 240px);\n  display: flex;\n  justify-content: center;\n}\n\n.welcome-image {\n  background-image: url(\"../../assets/img/welcome-img.svg\");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position-y: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
