import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { Check } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";

const BtnRecarregarPesquisa = ({ func1, func2 }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
        paddingTop: 10,
      }}
    >
      <Button variant="contained" size="small" color="success" onClick={func1}>
        <Check /> <span style={{ marginLeft: 5 }}>SIM</span>
      </Button>
      <Button variant="contained" size="small" color="error" onClick={func2}>
        <Close />
        <span style={{ marginLeft: 5 }}>NÃo</span>
      </Button>
    </div>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddNotification({
  notification = { action: null },
  setNotification,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ ...notification, open: false });
  };

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={notification.severity}
        sx={{ width: "100%" }}
      >
        {notification.message}
        {notification.action ? (
          <BtnRecarregarPesquisa
            func1={() => {
              notification.action.accept();
              setNotification({ ...notification, open: false });
            }}
            func2={() => {
              notification.action.reject();
              setNotification({ ...notification, open: false });
            }}
          />
        ) : null}
      </Alert>
    </Snackbar>
  );
}
