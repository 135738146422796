import { memo, useEffect, useState } from "react";
import {
  Button,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  Checkbox,
  Typography,
} from "@mui/material";
import { maskCurrency } from "../../Utils";
import "./style.css";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import DefaultDropDown from "../DefaultDropdown";

export const DropableBox = memo(function DropableBox({
  dropedItens = [],
  title,
  onRemoveItem,
  onChangeQuantidade,
  removeAll,
  moveUp,
  moveDown,
  primeiro,
  ultimo,
  arrTitle,
  sort,
  handleMove,
}) {
  const [todosSelecionados, setTodosSelecionados] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [ordenar, setOrdenar] = useState("Nenhum");

  const handleAll = (e) => {
    const { checked } = e.target;

    if (checked) {
      setSelecionados(
        dropedItens.map((i, index) => ({
          _id: i._id,
          title: i.title,
          index: index,
        }))
      );
    } else {
      setSelecionados([]);
    }
  };

  const handleSelect = (item, e, index) => {
    const { checked } = e.target;
    if (checked) {
      setSelecionados([...selecionados, { _id: item._id, index: index }]);
    } else {
      setSelecionados(selecionados.filter((i) => i._id !== item._id));
    }
  };

  const handleRemoveItem = () => {
    if (!selecionados.length) {
      return;
    }
    onRemoveItem(selecionados);
  };

  useEffect(() => {
    if (selecionados.length > 0 && selecionados.length === dropedItens.length) {
      setTodosSelecionados(true);
    } else {
      setTodosSelecionados(false);
    }
  }, [selecionados]);

  return (
    <div className="drop-container" data-testid="dustbin">
      <Typography
        variant="overline"
        fontSize={20}
        style={{
          backgroundColor: "#daa520ba",
          padding: "5px",
          borderRadius: "3px",
        }}
        color="InfoText"
      >
        {title}
      </Typography>

      <div style={{ display: "inline-block", marginTop: 10 }}>
        {!ultimo && (
          <Tooltip title="Mover quadro para baixo">
            <IconButton onClick={moveDown}>
              <ArrowDownward />
            </IconButton>
          </Tooltip>
        )}
        {!primeiro && (
          <Tooltip title="Mover quadro para cima">
            <IconButton onClick={moveUp}>
              <ArrowUpward />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <Button color="error" className="remove-btn" onClick={removeAll}>
        Excluir lista
      </Button>

      {dropedItens.length ? (
        <div
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox checked={todosSelecionados} onChange={handleAll} />{" "}
            <span style={{ marginRight: 30 }}>
              <b>SELECIONAR TODOS:</b>
            </span>
            <DefaultDropDown
              title="Mover para"
              data={arrTitle}
              onSelect={(e) => {
                handleMove(selecionados, e.id);
                setSelecionados([]);
              }}
            />
            <Button
              onClick={handleRemoveItem}
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
            >
              Excluir
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 15,
            }}
          >
            <span>
              <b>Ordenar por:</b>
            </span>
            <DefaultDropDown
              variant="outlined"
              title={ordenar || "Nenhum"}
              onSelect={(e) => {
                sort(e);
                setOrdenar(e.title);
              }}
              data={[
                { title: "Nenhum", id: -1 },
                { title: "Menor valor", id: 0 },
                { title: "Maior valor", id: 1 },
              ]}
            />
          </div>
        </div>
      ) : null}

      {dropedItens.length > 0 &&
        dropedItens.map((i, index) => (
          <ListItem
            key={index}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "90%" }}>
              <Checkbox
                onChange={(e) => handleSelect(i, e, index)}
                checked={selecionados.some((j) => j._id === i._id)}
              />
              <b style={{ marginRight: 8 }}>{index + 1}.</b>
              {i.fornecedor.fornecedor_razao_social} / {i.descricao_item} -{" "}
              {maskCurrency(i.valorUnitario)}
            </div>
            <div style={{ width: "10%" }}>
              <TextField
                label="Quantidade"
                type="number"
                value={i.quantidade_item}
                onChange={(e) => onChangeQuantidade(e, index)}
                size="small"
              />
            </div>
          </ListItem>
        ))}
    </div>
  );
});
