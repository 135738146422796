import React, { useState } from "react";
import { Add, Close, Refresh } from "@mui/icons-material";
import {
  Button,
  Card,
  Checkbox,
  ClickAwayListener,
  Fab,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";

import { sleep } from "../../Utils/index";
import moment from "moment";

const AddButton = ({
  lista = [],
  setLista,
  selecionados,
  clear,
  setSelecionados,
  addNotification,
  remover = [],
}) => {
  const [open, setOpen] = useState(false);
  const [_checked, setChecked] = useState([]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleNovaLista = () => {
    const id = moment().format("YYYYMMDDHHmmss");
    setLista((old) => [
      ...old,
      { id: Number(id), nome: "", quantidade: "", itens: [] },
    ]);
    setChecked([Number(id)]);
  };

  const setNome = (e, index) => {
    const { value, name } = e.target;
    let _lista = Object.assign([], lista);
    _lista[index][name] = value;
    setLista(_lista);
  };

  const handleSave = async () => {
    let _lista = Object.assign([], lista);

    _lista.forEach((i) => {
      if (_checked.includes(i.id)) {
        let nItens = i.itens.filter(
          (i) => remover.findIndex((j) => i.id === j.id) === -1
        );

        const quantidadeAtualizada = [
          ...new Set([...selecionados, ...nItens]),
        ].map((p) => ({ ...p, quantidade_item: i.quantidade }));

        i.itens = quantidadeAtualizada;
      }
    });

    setLista(_lista);

    addNotification(
      "Itens enviados para lista de impressão, para recuperar a pesquisa basta clicar no botão de reload ao lado da mesma",
      true,
      "success"
    );
    clear(true);
  };

  const handleRemove = () => {
    let _lista = Object.assign([], lista);
    setLista(_lista.filter((i) => !_checked.includes(i.id)));
  };

  const handleSelect = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setChecked([..._checked, Number(value)]);
    } else {
      setChecked(_checked.filter((i) => i !== Number(value)));
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="styled-float-bnt" style={{ right: 120 }}>
        {open ? (
          <Card className="card-adicionar">
            <Grid container style={{ overflowY: "scroll" }}>
              {lista.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Lista de pesquisa</span>
                  <IconButton size="small" onClick={toggleOpen} color="error">
                    <Close />
                  </IconButton>
                </Grid>
              ) : null}
              <div style={{ marginBottom: 90, marginTop: 10 }}>
                {lista.length
                  ? lista.map((i, index) => (
                      <Grid container gap={1}>
                        <Grid key={i.id} item xs={1}>
                          <Checkbox
                            value={i.id}
                            checked={_checked.includes(Number(i.id))}
                            onChange={handleSelect}
                          />
                        </Grid>
                        <Grid key={i.id} item xs={6}>
                          <TextField
                            value={i.nome}
                            size="small"
                            name="nome"
                            onChange={(e) => setNome(e, index)}
                            placeholder="Título da pesquisa"
                          />
                        </Grid>
                        <Grid key={i.id} item xs={3}>
                          <TextField
                            type="number"
                            size="small"
                            value={i.quantidade}
                            name="quantidade"
                            onChange={(e) => setNome(e, index)}
                            placeholder="QNT"
                          />
                        </Grid>
                        <Grid key={i.id} item xs={1}>
                          <IconButton
                            title="Retomar selecionados"
                            onClick={async () => {
                              clear(true);
                              await sleep(500);
                              setChecked([i.id]);
                              setSelecionados((old) =>
                                [...old, ...i.itens].filter(
                                  (i) => !remover.includes(i)
                                )
                              );
                            }}
                          >
                            <Refresh />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  bottom: 10,
                  gap: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 15,
                  }}
                >
                  {lista.length ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleSave}
                      style={{ width: 100 }}
                    >
                      Salvar
                    </Button>
                  ) : null}
                  {lista.length ? (
                    <Button
                      color="error"
                      onClick={handleRemove}
                      style={{ width: 100 }}
                      size="small"
                    >
                      Remover
                    </Button>
                  ) : null}
                </div>
                <Button
                  size="small"
                  style={{ width: 200, margin: "0 auto" }}
                  onClick={handleNovaLista}
                >
                  <Add /> Adicionar nova lista
                </Button>
              </div>
            </Grid>
          </Card>
        ) : null}
        <Fab color="success" onClick={toggleOpen}>
          <Add />
        </Fab>
      </div>
    </ClickAwayListener>
  );
};

export default AddButton;
