import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { formatted, maskCurrency } from "../../Utils";
import "./style.css";
import DefaultModal from "../DefaultModal";
import {
  Construction,
  Description,
  LocationCity,
  Gavel,
} from "@mui/icons-material";
import moment from "moment";
import {
  atualizarContatoFornecedor,
  pesquisarFornecedor,
} from "../../Services";

const notFound = "-----";

const ModalInfo = ({ open, onClose, data, addNotification, setData }) => {
  const [tab, setTab] = useState("4");
  const [loading, setLoading] = useState(false);
  const handleChange = (e, v) => {
    setTab(v);
  };

  const handleAtualizarFornecedor = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await pesquisarFornecedor(data.fornecedor.fornecedor_cnpj);
    if (res.success) {
      const { email, telefone } = res.data.data;
      if (email && telefone) {
        const body = {
          id: data.fornecedor._id,
          email: email,
          telefone: `${telefone}`,
        };

        const atualizar = await atualizarContatoFornecedor(body);
        if (atualizar.success) {
          setData((old) => {
            let index = old.findIndex((i) => i._id === data._id);
            old[index].fornecedor.telefone = body.telefone;
            old[index].fornecedor.email = body.email;
            return old;
          });
        } else {
          addNotification(
            "Não foi possível realizar a pesquisa, tente novamente mais tarde!",
            true,
            "warning"
          );
        }
      } else {
        addNotification(
          "O fornecedor não possui dados de contato na base de dados da receita federal!",
          true,
          "info"
        );
      }
    } else {
      addNotification(
        "Não foi possível realizar a pesquisa, tente novamente mais tarde!",
        true,
        "warning"
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setTab("4");
    }
  }, [open]);

  return (
    <DefaultModal
      title="INFORMAÇÕES DETALHADAS"
      style={{ width: "75vw", height: "65vh" }}
      open={open}
      onClose={onClose}
      children={
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label={
                    <Typography variant="body2" className="tab-style">
                      <Gavel />
                      Dados do pregão
                    </Typography>
                  }
                  value="2"
                />
                <Tab
                  label={
                    <Typography variant="body2" className="tab-style">
                      <LocationCity />
                      Dados da uasg
                    </Typography>
                  }
                  value="3"
                />
                <Tab
                  label={
                    <Typography variant="body2" className="tab-style">
                      <Description />
                      Dados do fornecedor
                    </Typography>
                  }
                  value="1"
                />
                <Tab
                  label={
                    <Typography variant="body2" className="tab-style">
                      <Construction />
                      Dados do item
                    </Typography>
                  }
                  value="4"
                />
              </TabList>
            </Box>
            <TabPanel value="1" className="panel-overflow">
              <Grid container rowGap={2}>
                <Grid item xs={5}>
                  <Typography className="title-item">Nome Fantasia</Typography>
                  <Typography>
                    {data.fornecedor?.fornecedor_nome_fantasia || notFound}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="title-item">Razão Social</Typography>
                  <Typography>
                    {data.fornecedor?.fornecedor_razao_social || notFound}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">CNPJ:</Typography>
                  <Typography>
                    {data.fornecedor?.fornecedor_cnpj
                      ? formatted.cnpjCpfMask(data.fornecedor.fornecedor_cnpj)
                      : notFound}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className="title-item">Município</Typography>
                  <Typography>
                    {data.fornecedor?.fornecedor_municipio}-
                    {data.fornecedor?.fornecedor_uf}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="title-item">Logradouro</Typography>
                  <Typography>
                    {data.fornecedor?.fornecedor_logradouro || notFound}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">CEP</Typography>
                  <Typography>
                    {data.fornecedor?.fornecedor_cep
                      ? formatted.cep(data.fornecedor.fornecedor_cep)
                      : notFound}
                  </Typography>
                </Grid>
                {data.fornecedor?.email ? (
                  <>
                    <Grid item xs={5}>
                      <Typography className="title-item">Email</Typography>
                      <Typography>{data.fornecedor?.email}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className="title-item">Telefone</Typography>
                      <Typography>
                        {data.fornecedor?.telefone
                          ? data.fornecedor?.telefone
                          : "Não informado"}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Button
                      onClick={handleAtualizarFornecedor}
                      style={{ width: 300 }}
                      color="info"
                      variant="outlined"
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Buscar contato fornecedor"
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="2" className="panel-overflow">
              <Grid container rowGap={2}>
                <Grid item xs={3}>
                  <Typography className="title-item">Número pregão:</Typography>
                  <Typography>
                    {data.contrato?.contrato_id.split("-")[0] || notFound}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">
                    Número processo:
                  </Typography>
                  <Typography>
                    {data.contrato?.numero_processo || notFound}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">
                    Data Publicação:
                  </Typography>
                  <Typography>
                    {data.contrato?.data_publicacao
                      ? moment(data.contrato.data_publicacao).format(
                          "DD/MM/YYYY"
                        )
                      : notFound}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">Tipo:</Typography>
                  <Typography>Eletrônico</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className="title-item">Objeto:</Typography>
                  <Typography align="justify">
                    {data.contrato?.objeto_licitacao}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="title-item">Link:</Typography>
                  <a
                    download="pdf"
                    align="justify"
                    href={data.contrato?.link}
                    target="_blank"
                  >
                    {data.contrato?.link}
                  </a>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3" className="panel-overflow">
              <Grid container rowGap={2}>
                <Grid item xs={6}>
                  <Typography className="title-item">Nome:</Typography>
                  <Typography>{data.uasg?.uasg_nome || notFound}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="title-item">Município:</Typography>
                  <Typography>
                    {data.uasg?.uasg_municipio || notFound}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="title-item">CEP:</Typography>
                  <Typography>{data.uasg?.uasg_cep || notFound}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">UF:</Typography>
                  <Typography>
                    {data.uasg?.uasg_sigla_uf || notFound}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="4" className="panel-overflow">
              <Grid container rowGap={2}>
                <Grid item xs={3}>
                  <Typography className="title-item">Quantidade:</Typography>
                  <Typography>{data.quantidade_item || notFound}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">
                    Unidade fornecimento:
                  </Typography>
                  <Typography>
                    {data.unidade_fornecimento || notFound}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">Situação:</Typography>
                  <Typography>Homologado</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">
                    Valor homologado:
                  </Typography>
                  <Typography>{maskCurrency(data.valorUnitario)}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="title-item">Marca</Typography>
                  <Typography>{data.marca || notFound}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="title-item">Fabricante:</Typography>
                  <Typography>{data.fabricante || notFound}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="title-item">
                    Descrição geral:
                  </Typography>
                  <Typography align="justify">
                    {data.descricao_item || notFound}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="title-item">
                    Descrição detalhada:
                  </Typography>
                  <Typography align="justify">
                    {data.descricao_detalhada_item || notFound}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      }
    />
  );
};

export default ModalInfo;
