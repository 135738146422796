import React, { useRef, useEffect } from "react";
import wppIcon from "../assets/img/whatsapp-icon.png";
import aguasDeLindoia from "../assets/img/aguasdelindoia.png";
import aruja from "../assets/img/aruja.png";
import ciop from "../assets/img/ciop.png";
import itabirito from "../assets/img/itabirito.png";
import itaoca from "../assets/img/itaoca.png";
import andradina from "../assets/img/brasao_andradina.png";
import bastos from "../assets/img/brasao-bastos.png";
import adamantina from "../assets/img/brasao-adamantina.png";
import balsamo from "../assets/img/brasao-balsamo.png";
import socorro from "../assets/img/brasao-socorro.png";
import guaraci from "../assets/img/brasao-guaraci.png";
import logo from "../assets/img/WhatsApp_Image_2022-10-31_at_3.18.22_PM-removebg-preview.png";
import sobrenos from "../assets/img/about-us.jpg";
import ContentPasteSearchSharpIcon from "@mui/icons-material/ContentPasteSearchSharp";
import { Email, LocalPrintshopSharp, Phone } from "@mui/icons-material";
import LightbulbSharpIcon from "@mui/icons-material/LightbulbSharp";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";

const SERVICOS = [
  {
    color: "#14161b",
    icone: <ContentPasteSearchSharpIcon style={{ height: 80 }} />,
    titulo: "Relatórios",
    descUm: "Barra de pesquisa avançada, com diversas opções de filtros;",
    descDois:
      "Adequação dos valores de média, mediana, maior e menor valor automaticamente;",
    descTres: "Fontes de pesquisa 100% homologadas e atualizadas;",
    borderRight: 0,
    borderLeft: 20,
  },
  {
    color: "#5b6f76",
    icone: <LocalPrintshopSharp style={{ height: 80 }} />,
    titulo: "Exportação",
    descUm: "Emissão de relatórios em dois formatos: PDF e EXCEL;",
    descDois: "Itens ordenados conforme esboço criado pelo usuário;",
    descTres:
      "Exibição de média, mediana, maior e menor valor ao final de cada bloco de pesquisa;",
    borderRight: 0,
    borderLeft: 0,
  },
  {
    color: "#93b5c4",
    icone: <LightbulbSharpIcon style={{ height: 80 }} />,
    titulo: "Salvar modelos",
    descUm:
      "Exclusiva função de gravação de três modelos (dependendo do plano contratado) de relatórios;",
    descDois: "Edição e exclusão de modelos de relatórios;",
    descTres: "Exportação de modelos de relatório em PDF e EXCEL;",
    borderRight: 20,
    borderLeft: 0,
  },
];

const PARCEIROS = [
  { src: aguasDeLindoia, maxHeight: 120, maxWidth: 80 },
  { src: aruja, maxHeight: 80, maxWidth: 70 },
  { src: ciop, maxHeight: 150, maxWidth: 120 },
  { src: itabirito, maxHeight: 120, maxWidth: 80 },
  { src: itaoca, maxHeight: 120, maxWidth: 80 },
  { src: andradina, maxHeight: 130, maxWidth: 90 },
  { src: bastos, maxHeight: 120, maxWidth: 80 },
  { src: adamantina, maxHeight: 120, maxWidth: 80 },
  { src: balsamo, maxHeight: 120, maxWidth: 90 },
  { src: socorro, maxHeight: 120, maxWidth: 80 },
  { src: guaraci, maxHeight: 120, maxWidth: 80 },
];

const PLANOS = [
  { titulo: "Plano básico", quantidade: 3 },
  { titulo: "Plano médio", quantidade: 5 },
  { titulo: "Plano máx", quantidade: 10 },
];

const RevealSection = ({ children }) => {
  const sectionRef = useRef(null);

  const handleScroll = () => {
    const revealSection = sectionRef.current;
    if (revealSection) {
      const { top } = revealSection.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (top < windowHeight * 1) {
        revealSection.classList.add("reveal");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section ref={sectionRef} className="reveal-section">
      {children}
    </section>
  );
};

const LandingPage = () => {
  const navigation = useNavigate();
  const text =
    "O modo mais rápido e fácil de iniciar os seus processos de compras, tenha informações atualizadas das principais fontes de dados do Brasil...";
  const speed = 75; // Adjust the speed (in milliseconds) to control the typing speed

  let index = 0;

  function typeWriter() {
    if (index < text.length) {
      const typewriterText = document.getElementById("typewriter-text");
      typewriterText.innerHTML += text.charAt(index);
      index++;
      setTimeout(typeWriter, speed);
    }
  }
  useEffect(() => {
    typeWriter();
  }, []);

  return (
    <div className="landing-page">
      <header className="header">
        <div className="opening-hours">
          <p>
            Horário de Funcionamento:
            <br /> Segunda-Sexta, 9h-18h
          </p>
        </div>
        <div className="logo">
          <img src={logo} />
          <p>reços públicos online</p>
        </div>
        <div className="login-button">
          <button onClick={() => navigation("/login")}>Login</button>
        </div>
      </header>

      <main>
        {/* Carousel */}
        <section>
          <div className="main-image">
            <div className="glassmorphism-card" id="write-card">
              <div id="typewriter">
                <p id="typewriter-text"></p>
              </div>
            </div>
          </div>
        </section>

        {/* Sobre nós */}
        <RevealSection>
          <section className="about-section">
            <div className="about-content">
              <h2>Quem somos:</h2>
              <p>
                A empresa Preços Públicos surgiu no ramo tecnológico com o
                intuito de aprimorar e facilitar o relacionamento interno dos
                órgãos públicos, com leve direcionamento também para as empresas
                que necessitam de agilidade e confiança nas suas negociações.
              </p>
              <p>
                A Preços Públicos atua diariamente em formato de parceria,
                ouvindo e desenvolvendo soluções constantemente, permitindo que
                vocês, clientes, tenham o melhor atendimento possível ao
                utilizar a plataforma.
              </p>
              <p>
                O objetivo da empresa encontra-se pautado na praticidade e na
                qualidade dos serviços prestados.
              </p>
            </div>
            <div className="about-image">
              <img src={sobrenos} alt="About Us Image" />
            </div>
          </section>
        </RevealSection>

        {/* Nossos servicos */}
        <RevealSection>
          <section className="services">
            <h2 className="h2-subtitle">Nossos serviços</h2>
            <div>
              <div className="half-blue" />
            </div>

            <div className="services-container">
              {SERVICOS.map((i, index) => (
                <div
                  style={{
                    backgroundColor: i.color,
                    borderTopLeftRadius: i.borderLeft,
                    borderBottomLeftRadius: i.borderLeft,
                    borderTopRightRadius: i.borderRight,
                    borderBottomRightRadius: i.borderRight,
                  }}
                  key={index}
                >
                  {i.icone}
                  <h3 style={{ height: 20 }}>{i.titulo}</h3>
                  <div style={{ height: 180 }}>
                    <span>1. {i.descUm}</span>
                    <span>2. {i.descDois}</span>
                    <span>3. {i.descTres}</span>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </RevealSection>

        {/* Precos */}
        <RevealSection>
          <section>
            <h2 className="h2-subtitle">Nossos planos</h2>
            <div className="pricing-cards">
              {PLANOS.map((i, index) => (
                <div className="pricing-card" key={index}>
                  <h3>{i.titulo}</h3>
                  <ul>
                    <li>Pesquisa na base de dados;</li>
                    <li>{i.quantidade} logins diferentes;</li>
                    <li>
                      {i.quantidade} modelos de relatórios para salvamento;
                    </li>
                  </ul>
                  <a
                    href={`https://api.whatsapp.com/send?phone=554188169927&text=${encodeURIComponent(
                      `Olá, gostaria de ter mais informações sobre o ${i.titulo} do sistema de preços públicos`
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Conhecer plano
                  </a>
                </div>
              ))}
            </div>
          </section>
        </RevealSection>

        {/* Nossos clientes */}
        <RevealSection>
          <h2 className="h2-subtitle">Nossos parceiros</h2>
          <div className="client-logos">
            <Carousel
              infiniteLoop
              autoPlay
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              selectedItem={3}
              centerMode={true}
              centerSlidePercentage={33.3}
            >
              {PARCEIROS.map((i, index) => (
                <img
                  key={index}
                  src={i.src}
                  style={{ maxHeight: i.maxHeight, maxWidth: i.maxWidth }}
                  alt={`parceiro-${index}`}
                />
              ))}
            </Carousel>
          </div>
        </RevealSection>
      </main>

      <div className="whatsapp-float">
        <a
          href={`https://api.whatsapp.com/send?phone=554188169927&text=${encodeURIComponent(
            "Olá, gostaria de ter mais informações sobre o sistema de preços públicos"
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={wppIcon} alt="WhatsApp Icon" />
        </a>
      </div>
      <footer>
        <div className="contato">
          <h2>Contato</h2>
          <p>
            <Email style={{ marginRight: 8 }} fontSize="14px" />{" "}
            precospublicos@gmail.com
          </p>
          <p>
            <Phone style={{ marginRight: 8 }} fontSize="14px" /> +55 41 9
            8816-9927
          </p>
        </div>
        <div>
          <p>
            &copy; {moment().format("YYYY")} Preços públicos online. All rights
            reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
